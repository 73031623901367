<template>
  <div class="page">
    <!-- bannière -->
    <swiper :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
      <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
      <swiper-slide class="swiper-slide historique">
        <div class="content-slider">
          <div class="title" data-swiper-parallax="-100">
            <b>Rendez-vous</b>
          </div>
        </div>
      </swiper-slide>
    </swiper>

    <div class=" body">
      <!-- Stats : Le total de chaque statut -->
      <div class="vx-row colors cursor-pointer">
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5">
          <vx-card
            :title="aVenir"
            v-on:click.native="getAppointments({ statut: 'A venir' })"
            subtitle="A venir"
            :title-color="colors.white"
            :card-background="colors.orange2"
            :content-color="colors.white">
          </vx-card>
        </div>

        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5 cursor-pointer ">
          <vx-card
            :title="aStatuer"
            subtitle="A statuer"
            v-on:click.native="getAppointments({ statut: 'A statuer' })"
            :title-color="colors.white"
            :card-background="colors.green2"
            :content-color="colors.white">
          </vx-card>
        </div>

        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5 cursor-pointer">
          <vx-card
            :title="termine"
            subtitle="Réalisé"
            v-on:click.native="getAppointments({ statut: 'Réalisé' })"
            :title-color="colors.white"
            :card-background="colors.yellow2"
            :content-color="colors.white">
          </vx-card>
        </div>
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5 cursor-pointer">
          <vx-card
            :title="enAttenteAnnul"
            subtitle="En attente d'annulation"
            v-on:click.native="getAppointments({ statut: 'En attente d\'annulation' })"
            :title-color="colors.white"
            :card-background="colors.primary"
            :content-color="colors.white"
            class="no-padding">
          </vx-card>
        </div>
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/5 cursor-pointer">
          <vx-card
            :title="annule"
            subtitle="Annulés"
            v-on:click.native="getAppointments({ statut: 'Annulé' })"
            :title-color="colors.white"
            :card-background="colors.blue2"
            :content-color="colors.white">
          </vx-card>
        </div>
      </div>

      <!-- résultats -->
      <vx-card>
        <div class="flex flex-wrap justify-between items-center">
          <!-- Nombre de résultat par page -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light
                  rounded-full d-theme-dark-bg cursor-pointer flex
                  items-center justify-between font-medium"
                >
                <span class="mr-2">
                  {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
                  - {{ rendezVous.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : rendezVous.length }}
                  of {{ rendezVous.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- Recherche et export CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
            <vs-button class="mb-4 md:mb-0" @click="exportDataAsCsv()">Exporter csv</vs-button>
          </div>
        </div>

        <!-- Tableau -->
        <div class="mt-5">
          <!-- Onglets -->
          <vs-tabs>
            <vs-tab label="Afficher tout" @click="getAppointments()" />
            <vs-tab label="Rendez-vous Physique" @click="getAppointments({ type: 'physique' })" />
            <vs-tab
              label="Rendez-vous téléphonique"
              @click="getAppointments({ type: 'telephonique' })"
            />
          </vs-tabs>

          <!-- Tableau -->
          <!-- @TODO: https://www.ag-grid.com/vue-data-grid -> serverside Data -->
          <div class="tab-text vs-con-loading__container" id="data-table">
            <ag-grid-vue
              class="ag-theme-material w-100 my-4 ag-grid-table"
              colResizeDefault="shift"
              rowSelection="multiple"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :enableRtl="$vs.rtl"
              :floatingFilter="true"
              :gridOptions="gridOptions"
              :localeText="localeText"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              :rowData="rendezVous"
              :suppressPaginationPanel="true"
            />
            <vs-pagination
              :total="totalPages"
              :max="maxPageNumbers"
              v-model="currentPage"
            />
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { AgGridVue } from 'ag-grid-vue';
import { colors } from '@/../themeConfig';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';

const AG_GRID_LOCALE_FR = {
  filterOoo: 'Filter...',
  equals: 'Égale',
  notEqual: 'Différent de ',
  empty: 'Vide',
  inRange: 'Entre',
  greaterThan: 'Supérieur à',
  lessThan: 'Inférieur à',
  contains: 'Contient',
  notContains: 'Ne contient pas',
  startsWith: 'Commence par',
  endsWith: 'Finit par',
  and: 'ET',
  or: 'Ou',
};
const filterParams = {
  comparator(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const datePartsD = dateAsString.split(' ');
    const dateParts = datePartsD[0].split('/');
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1] - 1),
      Number(dateParts[0]),
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
  monthToNum(date) {
    if (date === undefined || date === null) {
      return null;
    }
    const datePartsD = date.split(' ');
    const dateParts = datePartsD[0].split('/');
    const timeParts = datePartsD[1].split(':');
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1] - 1),
      Number(dateParts[0]),
      Number(timeParts[0]),
      Number(timeParts[1]),
      Number(timeParts[2]),
    );
    return cellDate.getTime();
  },
};

export default {
  name: 'AdminAppointment',
  components: {
    swiper,
    swiperSlide,
    AgGridVue,
  },
  props: ['statut'],
  filters: {
    priceFormat(value) {
      if (value) {
        const val = (value / 1).toFixed(2).replace('.', ' ');
        return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} € `;
      }
    },
  },
  data() {
    const { $router } = this;
    return {
      // Area charts
      localeText: AG_GRID_LOCALE_FR,
      first: '',
      last: '',
      next: '',
      searchQuery: '',
      idLigne: this.$route.query.id,
      gridOptions: {
        onRowClicked(event) {
          $router.push({ name: 'admin-appointments-id', params: { id: event.data.id } });
        },
        getRowNodeId(data) { return data.id; },
      },
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      csvRdv: [],
      termine: '',
      colors,
      annule: '',
      aStatuer: '',
      enAttenteAnnul: '',
      aVenir: '',
      rendezVous: '',
      columnDefs: [
        {
          headerName: 'Statut',
          field: 'statut',
          filter: false,
          cellRenderer(params) {
            let classe = '';
            switch (params.value) {
              case 'Réalisé':
                classe = 'Termine';
                break;
              case 'Annulé':
                classe = 'Annule';
                break;
              case 'A statuer':
                classe = 'Astatuer';
                break;
              case "En attente d'annulation":
                classe = 'enAttenteAnnul';
                break;
              default:
                classe = 'Avenir';
            }
            return `<div class="status"><span class="${classe}">${params.value}</span></div>`;
          },
          width: 100,
        },
        {
          headerName: 'Id',
          field: 'id',
          filter: true,
          width: 50,
          cellRenderer(params) {
            return params.value;
          },
        },
        {
          headerName: 'Date de création',
          field: 'dateCreation',
          filter: 'agDateColumnFilter',
          filterParams,
          comparator(date1, date2) {
            const date1Number = filterParams.monthToNum(date1);
            const date2Number = filterParams.monthToNum(date2);
            if (date1Number === null && date2Number === null) {
              return 0;
            }
            if (date1Number === null) {
              return -1;
            }
            if (date2Number === null) {
              return 1;
            }

            return date1Number - date2Number;
          },
          width: 250,
        },
        {
          headerName: 'Date et heure du Rdv',
          field: 'dateDebut',
          filter: 'agDateColumnFilter',
          filterParams,
          comparator(date1, date2) {
            const date1Number = filterParams.monthToNum(date1);
            const date2Number = filterParams.monthToNum(date2);
            if (date1Number === null && date2Number === null) {
              return 0;
            }
            if (date1Number === null) {
              return -1;
            }
            if (date2Number === null) {
              return 1;
            }

            return date1Number - date2Number;
          },
          width: 250,
        },
        {
          headerName: 'Nom négociateur',
          field: 'negociateur.lastName',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Prénom négociateur',
          field: 'negociateur.firstName',
          filter: true,
          width: 150,
        },
        {
          headerName: 'email négociateur',
          field: 'negociateur.email',
          filter: true,
          width: 130,
          hide: true,
        },
        {
          headerName: 'Nom téleconseiller',
          field: 'teleconseiller.lastName',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Prénom téleconseiller',
          field: 'teleconseiller.firstName',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Commande',
          field: 'commande.reference',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Formule',
          field: 'commande.formule',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Annonce',
          field: 'ad.reference',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Nom vendeur',
          field: 'ad.nomVendeur',
        },
        {
          headerName: 'Mobile',
          field: 'ad.mobileNumber',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Commune',
          field: 'ad.adCity',
          filter: true,
          width: 130,
        },
        {
          headerName: 'Adresse',
          field: 'ad.adresseBien',
        },
        {
          headerName: 'CP',
          field: 'ad.postalCode',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Type de bien',
          field: 'ad.tradeType.name',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Prix',
          field: 'ad.price',
          filter: true,
          cellRenderer(params) {
            const val = (params.value / 1).toFixed(0).replace('.', ' ');
            return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} € `;
          },
          width: 100,
        },
        {
          headerName: 'Mandat',
          field: 'mandat',
          filter: true,
          width: 80,
        },
        {
          headerName: 'Mobile',
          field: 'ad.mobileNumber',
          filter: true,
          width: 150,
        },

        {
          headerName: 'Date annulation',
          field: 'dateAnnulation',
          filter: true,
          width: 250,
        },
        {
          headerName: 'Commentaire',
          field: 'commentaire',
          filter: true,
          width: 200,
        },
      ],
      requests: {
        getAppointmentsRequest: {
          abortController: null,
        },
      },
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    exportDataAsCsv() {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: 'RendezVous',
        columnSeparator: ';',
      };
      this.gridApi.exportDataAsCsv(params);
    },
    getCsvData(rendezVous) {
      let oneLine = {};
      const dataResult = [];
      rendezVous.forEach((value) => {
        oneLine = {
          id: value.id,
          commande: value.commande && `${value.commande.id} | ${value.commande.reference}`,
          negociateur: value.negociateur && `${value.negociateur.firstName} ${value.negociateur.lastName}`,
          teleconseiller: value.teleconseiller ? `${value.teleconseiller.firstName} ${value.teleconseiller.lastName} , email :${value.teleconseiller.email}` : '',
          annonce: value.ad && value.ad.reference,
          commune: value.ad && `${value.ad.postalCode} ${value.ad.adCity}`,
          type_bien: value.ad && value.ad.tradeType.name,
          prix: value.ad && value.ad.price,
          Numero_telephone: value.ad && value.ad.mobileNumber,
          date: `${value.dateDebut} - ${value.dateFin}`,
          statut: value.statut,
          mandat: value.mandat,
          commentaire: value.commentaire,
          dateAnnulation: value.dateAnnulation,
          motifAnnulation: value.motifAnnulation,
        };
        dataResult.push(oneLine);
      });
      this.csvRdv = dataResult;
    },
    // Récupération des rendez-vous
    async getAppointments({ statut, type } = {}) {
      const loaderId = '#data-table';
      const { id, roles } = this.$store.state.AppActiveUser.user;
      const { abortController } = this.requests.getAppointmentsRequest;

      // Construction de l'url
      const url = this.$urlBuilder('/api/rendez_vouses')
        .appendQuery('statut', statut)
        .appendQuery('type', type)
        .appendQuery('teleconseiller', roles.includes('ROLE_SUPER_ADMIN') ? undefined : id);

      // Si requete existante alors on l'annule sinon on démarre le loading
      if (abortController) {
        abortController.abort();
      } else {
        this.$vs.loading({ container: loaderId });
      }

      // Création du signal d'annulation de requête
      const controller = new AbortController();
      this.requests.getAppointmentsRequest.abortController = controller;

      try {
        const { data } = await this.$http.get(url.format(), { signal: controller.signal });
        this.rendezVous = data;

        const csv = data.map((value) => {
          if (Object.prototype.hasOwnProperty.call(value, 'dateCreation')) {
            Object.assign(value, {
              dateCreation: moment(String(value.dateCreation)).format('DD/MM/YYYY HH:mm:ss'),
            });
          }
          if (Object.prototype.hasOwnProperty.call(value, 'dateDebut')) {
            Object.assign(value, {
              dateDebut: moment(String(value.dateDebut)).format('DD/MM/YYYY HH:mm:ss'),
            });
          }
          if (Object.prototype.hasOwnProperty.call(value, 'dateAnnulation')) {
            Object.assign(value, {
              dateAnnulation: moment(String(value.dateAnnulation)).format('DD/MM/YYYY HH:mm:ss'),
            });
          }
          return value;
        });
        this.getCsvData(csv);
      } catch (error) {
        this.rendezVous = [];
        this.$vs.notify({
          title: 'Erreur',
          text: this.$parseError(error).message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      } finally {
        if (!controller.signal.aborted) {
          this.$vs.loading.close(`${loaderId} > .con-vs-loading`);
          this.requests.getAppointmentsRequest.abortController = null;
        }
      }
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    details(id) {
      this.$router.push({ name: 'details', params: { id } });
    },
  },
  watch: {
    statut(newVal) {
      let statut = '';
      switch (newVal) {
        case 'realise':
          statut = 'Réalisé';
          break;
        case 'astatuer':
          statut = 'A statuer';
          break;
        case 'annules':
          statut = 'Annulé';
          break;
        case 'enAttenteAnnul':
          statut = "En attente d'annulation";
          break;
        default:
          statut = 'A venir';
      }
      this.getAppointments({ statut }); // or this.openPopup(newVal) is this suits
    },
  },
  mounted() {
    const { roles, id } = this.$store.state.AppActiveUser.user;
    let urlTermine = '';
    let urlAvenir = '';
    let urlAstatuer = '';
    let urlAnnule = '';
    let urlAttenteAnnul = '';
    if (roles.includes('ROLE_SUPER_ADMIN')) {
      urlAnnule = '/api/rendez_vouses?statut=Annulé';
      urlTermine = '/api/rendez_vouses?statut=Réalisé';
      urlAstatuer = '/api/rendez_vouses?statut=A statuer';
      urlAvenir = '/api/rendez_vouses?statut=A venir';
      urlAttenteAnnul = '/api/rendez_vouses?statut=En attente d\'annulation';
    } else {
      urlAnnule = `/api/rendez_vouses?statut=Annulé&teleconseiller=${id}`;
      urlTermine = `/api/rendez_vouses?statut=Réalisé&teleconseiller=${id}`;
      urlAstatuer = `/api/rendez_vouses?statut=A statuer&teleconseiller=${id}`;
      urlAvenir = `/api/rendez_vouses?statut=A venir&teleconseiller=${id}`;
      urlAttenteAnnul = `/api/rendez_vouses?statut=En attente d'annulation&teleconseiller=${id}`;
    }
    this.$http.get(urlAnnule)
      .then((response) => {
        this.annule = String(response.data.length);
      })
      .catch(() => {
        this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
      });

    this.$http.get(urlAvenir)
      .then((response) => {
        this.aVenir = String(response.data.length);
      });

    this.$http.get(urlTermine)
      .then((response) => {
        this.termine = String(response.data.length);
      });
    this.$http.get(urlAttenteAnnul)
      .then((response) => {
        this.enAttenteAnnul = String(response.data.length);
      });
    this.$http.get(urlAstatuer)
      .then((response) => {
        this.aStatuer = String(response.data.length);
      });
    // Les commandes
    let statut = '';
    switch (this.statut) {
      case 'realise':
        statut = 'Réalisé';
        break;
      case 'astatuer':
        statut = 'A statuer';
        break;
      case 'annules':
        statut = 'Annulé';
        break;
      case 'enAttenteAnnul':
        statut = "En attente d'annulation";
        break;
      default:
        statut = 'A venir';
    }

    this.getAppointments({ statut });
    this.gridApi = this.gridOptions.api;
  },
};
</script>

<style>
  .body {
    padding-top: 30px;
  }
  .swiper-slide.historique {
    background: url("../../../assets/images/Slider/historique.png");
    background-size: cover;
  }
  .colors .vx-card__title {
    width: 100%;
    text-align: center;
  }

  .colors .vx-card__title h4 {
    width: 100%;
    padding-bottom: 10px;
    font-size: 38px;
  }

  .container {
    padding: 100px 0;
  }

  .colors .vx-card__title h6 {
    color: #fff !important;
  }

  .colors {
    margin-bottom: 50px
  }
  .ag-cell a {color : #000000}
  a.details  {color : #ffffff !important;}

</style>
